.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.stats-container {
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  max-width: 771px;
  /* min-width: 630px; */
  gap: 10px;
  margin: 0px 20px;
}

.date-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-title-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 80px;
  align-items: flex-start;
  justify-content: space-between;
}

.export-button {
  background-color: #FED36B;
  color: white;
  font-weight: bold;
  border: 2px solid #FED36B;
  border-radius: 8px;
  cursor: pointer;
}

.export-button:hover {
  background-color: white;
  color: #FED36B;
  font-weight: bold;
  border: 2px solid #FED36B;
  border-radius: 8px;
}

.data-button {
  background-color: #47B9A8;
  color: white;
  font-weight: bold;
  border: 2px solid #47B9A8;
  border-radius: 8px;
  cursor: pointer;
  height: 50px;
}

.data-button:hover {
  background-color: white;
  color: #47B9A8;
  font-weight: bold;
  border: 2px solid #47B9A8;
  border-radius: 8px;
}

.inscrit-complet-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 6px 6px 30px 6px rgba(30, 30, 30, 0.5);
}

.inscrit-complet {
  display: flex;
  flex-direction: column;
}

.fr-idf-reg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.nb-container {
  display: flex; 
  /* flex-wrap: wrap;  */
  gap: 10px; 
  margin-bottom: 40px;
}

.nb-stats {
  height: auto;
  min-width: 380px;
  min-height: 280px;  
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 6px 6px 30px 6px rgb(30 30 30 / 50%);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.chart-va-inscription {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 6px 6px 30px 6px rgb(30 30 30 / 50%);
  min-width: 380px;
  height: 280px;
}

.container-reports {
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.reports {
  font-size: 11px;
  width: 50%;
  font-weight: 900;
  color: #999;
}

.pie-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media  (min-width: 950px) {
  .container {
      flex-wrap: wrap;
  }
}

@media  (max-width: 800px) {
  .nb-container {
      flex-wrap: wrap;
  }
}

@media  (max-width: 630px) {
  .stats-container {
      align-items: center;
    min-width: 50%;
  }
  .inscrit-complet-container {
    width: 100%;
    flex-direction: row;
    gap: 10%;
    justify-content: center;
  }
  .fr-idf-reg {
    flex-direction: column;
    gap: 20px;
  }
  .nb-container {
      flex-direction: column;
      gap: 20px;
  }
  .nb-stats {
    width: 100%;
  }
}