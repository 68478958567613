/* Les couleurs sont définies dans DEUX fichiers : ce fichier .css et colors.js */

:root {
  --main-red: #FF6F5B;
  --main-Bleu: rgba(0, 125, 255, 1);
  --main-Grey: #999999;
  --secondary-green:#B3D7B0;
  --secondary-yellow:#FFBF23;
  --main-yellow:#FED36B;
  --main-Green: rgba(76, 175, 80, 1);

}
