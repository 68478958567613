p, h1, h2, h3, h4, h5, h6 {
    border: 0;
    padding: 0;
    margin: 0;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}

.section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
}
.information-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.container-border {
    border: 2px solid #FF6F5B;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.section {
    display: flex;
    flex-direction: column;
    
}
.section-border {
    display: flex;
    gap: 40px;
    border: 2px solid #FF6F5B;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.applications-border {
    width: 100%;
    border: 2px solid #FF6F5B;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}
.informations {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@media  (max-width: 700px) {
    .section-border {
        flex-direction: column;
    }
  }

.section-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    margin-bottom: 10px;
}
.section-content-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    margin-bottom: 10px;
}

.section-content-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
}

.section-profile {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mission-needs {
    padding: 8px;
    border: 1px solid;
    color: #F4F2F2;
    border-radius: 10px;
    background-color: #FF6F5B;

}