.p-card {
  box-shadow: 10px 10px 50px 10px rgb(30 30 30 / 70%);
}

svg.injected-svg {
  width: 250px;
  height: 250px;
}

.p-tabview-nav {
  height: 60px;
  align-content: center;
  align-items: center;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.9rem;
  white-space: nowrap;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #666;
}

span.p-column-title {
  white-space: nowrap;
  font-size: 14px;
}
table.p-datatable-table {
  border: 1px solid #dee2e6;
}

.p-button.p-fileupload-choose {
  padding: 2px 0px 2px 4px;

}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  font-weight: normal;
}

i.pi.pi-save {
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 10px;
  float: right;
  display: block;
  color: #666;
  cursor: pointer;
}

i.pi.pi-trash {
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 540px;
  float: right;
  display: block;
  color: #757575;
}
i.pi.pi-user-minus {
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 505px;
  float: right;
  display: block;
  color: #757575;
}
i.pi.pi-star-fill{
  color: #fed36b;
}
i.pi.pi-star{
  color: #F4F2F2;
}

i.pi.pi-lock {
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 470px;
  float: right;
  display: block;
  color: red;
}

i.pi.pi-unlock {
  position: absolute;
  z-index: 9;
  top: 7px;
  left: 470px;
  float: right;
  display: block;
  color: green;
}

.p-tabview.p-component {
  background-color: white;
}

.p-dropdown-label.p-inputtext {
  font-size: 12px !important;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
  position: sticky;
  top: 0;
}
