.p-menu{
    height: 100%;
    border-right: 1px solid #666;
    border-left: 1px solid #666;
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
    margin-top: -3px;
    padding: 0;
    top: 0px;
    border-top: 1px solid #666;
    background-color: #444;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover{
    background-color: var(--main-red);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon{color:white}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{color:white}
nav{height:100%;}

.p-menu ul{

    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #444;
}
.p-menu .p-menuitem-link .p-menuitem-icon{color: #afafaf;}
.p-menu .p-menuitem-link .p-menuitem-text{color: #afafaf;font-size: 18px; margin-bottom: 10px;}
.p-menu .p-menuitem-link{white-space: nowrap; display: flex; align-items: baseline; gap: 10px;}
.Administration{position:absolute;bottom: 10px;width: calc(100% - 2px);}