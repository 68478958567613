.preparation-highlight-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
}


.preparation-highlight-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border: 2px solid black;
    border-radius: 10px;
}
