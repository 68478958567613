@import './colors.css';

#header-logo {
    width: 230px;
    float: left;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;  
    margin-left: 30px;
}

#header-showMenu {
    width: 48px;
    height: 48px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;  
    color:white;
    margin-left: 30px;
}
#header-btnToggleMenu {
    width: 100%;
    height: 100%;
    background-color: var(--main-yellow);
    border:3px solid #444;
    transition: background-color 0.15s ease;

}
#header-btnToggleMenu:hover {
    background-color: var(--main-red);
    transition: background-color 0.15s ease;
}



.header-userIcon {
    width: 2rem;
    height: 2rem;
    font-weight: 600;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    line-height: 1.75rem;
    float: right;
    margin: 10px;    position: absolute;
    right: 20px;
    border: 3px solid #444;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: center;
}
