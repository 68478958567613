.kpi-button-container {
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: center;
}

.label-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.additional-label-container {
    min-height: 47px;
}