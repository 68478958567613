.dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: stretch; 
    justify-content: center;
    width: 90%;
    gap: 20px;
}

.dashboard-section {
    display: flex;
    flex-direction: column;
    width: calc(100%/3); 
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 20px;

}
.dashboard-section-bottom {
    display: flex;
    flex-direction: column;
    width: 90%;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 20px;

}

.dashboard-buttons-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.dashboard-buttons {
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.dashboard-buttons:hover {
    opacity: 0.8;
}