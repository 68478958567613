@import "./colors.css";

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#skel-root {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.skel-home {
  height: 100%;
  background-color: rgba(254, 211, 107, 0.4);
}
.skel-profils {
  height: 100%;
  background-color: #f7b0a6;
}
.skel-reservation {
  height: 100%;
  background-color: #b3d7b0;
}
.skel-likes {
  height: 100%;
  background-color: #f7b0a6;
}

.skel-header {
  height: 70px;
  background-color: white;
  border-bottom: 3px solid #444;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;
}

.skel-menu-hidden {
  opacity: 1;
  display: block;
  width: 200px;
  z-index: 30;
  position: absolute;
  top: 100px;
  left: -200px !important;
  height: calc(100% - 100px);

  font-size: 13px;
  transition: all 0.3s ease-in-out;

  font-weight: 500;
  z-index: 31;
}
.skel-menu {
  opacity: 1;
  display: block;
  width: 200px;
  position: absolute;
  top: 73px;
  left: 0;
  z-index: 3001;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.skel-menu nav {
  top: 0;
  height: calc(100% - 0px);
}

.skel-content {
  height: calc(100% - 100px);
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  float: left;
  display: block;
  background-color: transparent;
  overflow: auto;
}

/**********************/

.blackDiv {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
}

/**********************/
/********FORM*********/
/**********************/
.genericFormBoilerplate {
  position: absolute;
  top: 8vh;
  left: calc(50% - 90% / 2);
  width: 90%;
  z-index: var(--z-index);
  display: flex;
  flex-direction: column-reverse;
  padding-top: 35px;
  background: transparent;
  max-height: 80vh;
}

#aa {
  color: transparent;
}

.BorderTop {
  height: 40px;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #dfdfdf;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.DraggableDiv {
  position: absolute;
  top: 0;
  z-index: 31;
  width: 100%;
  /* height: 100%; */
}

.formTitle {
  margin-top: 0;

  text-align: center;

  justify-content: center;
  display: flex;
  flex-direction: column;
}

.close {
  position: absolute;
  right: 25px;
  top: 0x;
  width: 16px;
  height: 16px;
}
.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 16px;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: #afafaf;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.closingButton {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  width: 50px;

  cursor: pointer;
  background-color: transparent;
  color: white;
  z-index: 13;
  height: 35px;
  justify-content: center;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
}

.closingButton:hover {
  background-color: rgb(218, 0, 0);
  transition: background-color 0.2s ease;
  border-top-right-radius: 10px;
  color: white;
}

.gen_insideForm {
  height: 100%;
  min-height: 350px;
  background: #eee;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);

  border: 1px solid rgba(255, 255, 255, 0.18);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  max-height: 800px;
  overflow: auto;
}
.gen_insideForm.overflowVisible {
  overflow: visible !important;
}

.homeDiv {
  width: 100%;
  height: 100%;
}

.tableComment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tableComment p {
  margin: 0;
}

.profilTable td {
  height: 65px;
}
