.kpi-btn-dtview-container {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    border: 1px solid;
    border-radius: 6px;
}

.kpi-btn-dtview-container-business {
    min-height: 130px;
    min-width: 190px;
}


.kpi-btn-dtview-inapp {
    min-width: 230px;
    min-height: 240px;
}

.kpi-dashboard-container {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    align-items: center,
}

.kpi-inscrits-complet-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    padding: 20px;
    border: 2px solid #47B9A8;
    border-radius: 10px;
    align-self: flex-start;
}

@media screen and (min-width: 1330px) {
    .kpi-inscrits-complet-container {
        height: 547px;
        gap: 70px;
    }
    
}
  
.kpi-inscrits-complet {
display: flex;
flex-direction: column;
}

.kpi-fr-idf-regs {
display: flex;
flex-direction: row;
gap: 40px;    
flex-wrap: wrap;
justify-content: center;
}

.dashboard-kpi-section-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.dashboard-kpi-section {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 20px;
    flex-wrap: wrap;

}
.dashboard-section-bottom {
    display: flex;
    flex-direction: column;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 20px;

}

.kpi-dashboard-buttons-section {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.kpi-dashboard-buttons-section-in-app > div {
    flex-basis: calc((100% - 80px) / 5); /* Space for 5 boxes minus the gaps */
    max-width: calc((100% - 80px) / 5);  /* Ensures no more than 5 boxes in a row */
}

.dashboard-buttons {
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.dashboard-buttons:hover {
    opacity: 0.8;
}