div.availibility {
  display: flex;
  border: 2px solid lightgrey;
  border-radius: 8px;
  padding: 12px;
  height: 168px;
  margin: 0 5px;
}

div.availibility div {
  height: 24px;
}

div.availibilityHeaderCol div {
  padding: 2px;
  width: 110px!important;
}
div.availibilityCol div {
  padding: 2px;
  width: 90px;
}
div.availibilityCol div:not(:first-child) {
  cursor: pointer;
}

div.availibilityHeaderCol, div.availibilityCol > div:first-child {
  font-weight: bold;
}

div.availibilityCol {
  text-align: center;
}

div.availibilityHeaderCol, div.availibilityCol {
  display: flex;
  flex-direction: column;
}

div.availibilityHeaderCol > div, div.availibilityCol > div {
  border-bottom: solid 1px grey;
  border-right: solid 1px grey;
}